<template>
  <v-overlay absolute :value="show" opacity="0.6">
    <v-card flat color="grey lighten-3" class="pa-5" max-width="440px">
      <v-card-title
        class="mb-0 text-h6 title font-weight-medium black--text text-break"
      >
        {{ $ml.get("scopesDialog_msDidNotRespond") }}
      </v-card-title>
      <v-card-subtitle class="ma-0 py-2 black--text">
        {{ $ml.get("scopesDialog_checkMsInstallation") }}
      </v-card-subtitle>
      <v-card-subtitle class="ma-0 mb-5 pt-0 black--text">
        {{ $ml.get("scopesDialog_inCaseMsIsInstalled") }}
      </v-card-subtitle>

      <v-btn
        :color="'primary'"
        height="48"
        width="100%"
        dark
        class="font-weight-bold text-none text-body-1 white--text"
        @click="$emit('close')"
      >
        Ok
      </v-btn>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  name: "MsScopesDialog",
  props: {
    show: Boolean,
  },
};
</script>

<style></style>
