<template>
  <PageLoading :messages="messages" />
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import PageLoading from "@/components/PageLoading.vue";

export default {
  name: "Callback",

  components: {
    PageLoading,
  },

  data: () => ({
    logo: require("@/assets/appslogos/icons/icon-control.png"),
    loadingAuthentication: true,
    authenticationError: null,
    retryToken: null,
    tries: 0,
    retryDelay: 8000,
    messages: ["", " "],
    retryMessages: [["retryJustInstalled"], ["retryEnsureScopes"]],
  }),

  computed: {
    ...mapGetters(["loadingSync", "authError"]),

    retriesExceeded() {
      return this.tries + 1 >= (60 * 2000) / this.retryDelay;
    },

    retryWaitingProvider() {
      return this.$ml.get("retryWaitingGoogle");
    },
  },

  methods: {
    ...mapActions([
      "generateToken",
      "exchangeToken",
      "logout",
      "syncUsersTask",
    ]),
    ...mapMutations(["setAuthError"]),

    goToLogin() {
      if (!this.loadingAuthentication) {
        this.$router.push({ name: "Login" });
      }
    },

    exchangeSuiteKey(suiteKey) {
      this.exchangeToken(suiteKey)
        .then(() => {
          this.routerPushNext();
        })
        .catch((error) => {
          this.$router.push({ name: "Login" });
          this.setAuthError(error);
        })
        .finally(() => {
          this.loadingAuthentication = false;
        });
    },

    routerPushNext() {
      this.syncUsersTask();
      const nextPage = localStorage.getItem("nextPage");
      if (nextPage) {
        this.$router.push({ name: nextPage });
        localStorage.removeItem("nextPage");
      } else {
        this.$router.push({ name: "Home" });
      }
    },

    tryToLogin(auth) {
      this.generateToken(auth)
        .then(() => {
          this.messages = ["", " "];
          this.routerPushNext();
        })
        .catch((error) => {
          const code = error.code;
          const invalidToken = [
            "INVALID_GOOGLE_ACCESS_TOKEN",
            "INVALID_MICROSOFT_ACCESS_TOKEN",
          ].includes(code);
          if (!invalidToken || this.retriesExceeded) {
            this.messages = ["", " "];
            this.$router.push({ name: "Login" });
            this.setAuthError(code);
          } else if (invalidToken) {
            this.updateMessages();
            this.retryToken = error.token || this.retryToken;
            this.retryToLogin();
          }
        });
    },

    retryToLogin() {
      setTimeout(() => {
        this.tries++;
        this.tryToLogin({
          headers: {
            Authorization: this.retryToken,
          },
          retry: this.tries > 0,
        });
      }, this.retryDelay);
    },

    updateMessages() {
      if (this.tries === 0) {
        this.messages = [this.retryWaitingProvider, " "];
      }
      setTimeout(() => {
        const messages = [this.retryWaitingProvider];
        if (this.tries === 0) {
          messages[1] = this.$ml.get("retryJustInstalled");
        } else {
          messages[1] = this.$ml.get(
            ...this.retryMessages[
              Math.round(Math.random()) * (this.retryMessages.length - 1)
            ]
          );
        }
        this.messages = messages;
      }, 2000);
    },
  },

  mounted() {
    const query = Object.assign({}, this.$route.query);
    this.$router.replace({ query: null });

    if (Object.keys(query).length) {
      this.messages = [this.$ml.get("authenticating"), " "];
      if (query.suiteKey) {
        this.exchangeSuiteKey(query.suiteKey);
      } else {
        this.tryToLogin({ params: query });
      }
    } else {
      this.routerPushNext();
    }
  },
};
</script>

<style lang="scss" scoped>
.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
