<script>
import Callback from "@/views/Callback.vue";
import { mapGetters, mapMutations } from "vuex";

export default {
  extends: Callback,

  computed: {
    ...mapGetters(["token"]),

    retryWaitingProvider() {
      return this.$ml.get("retryWaitingMicrosoft");
    },
  },

  methods: {
    ...mapMutations(["setToken"]),
    generateToken(auth) {
      return new Promise((resolve, reject) => {
        const url = auth.retry ? "/auth/authorizate" : "/auth/callback/ms";

        if (auth.retry) {
          delete auth.retry;
          auth.params = { oauth: "microsoft" };
        }

        return this.$axios
          .get(url, auth)
          .then(({ data }) => {
            this.setToken(data.token);
            return resolve(data.token);
          })
          .catch(({ response }) => {
            return reject(response.data);
          });
      });
    },
  },
};
</script>
