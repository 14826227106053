import {
  STATUS,
  SUBSCRIPTIONS,
  MIN_LICENSES,
} from "@/helpers/backendConstants";
import suiteAPIClient from "@/gateways/suiteapi";
import {
  getPriceByPeriod,
  getTotalPlanPrice,
  getTotalPlanPriceWithoutDiscount,
} from "@/helpers/billing";
import { defaultDiscounts } from "@/helpers/translateString";
import { hasDecimal, checkExpiredDate } from "@/helpers/utils2";

const state = {
  selectedPlanSKU: "",
  plans: [],
  loadingPlans: false,

  selfCheckoutPlan: null,
  maxLicenses: 0,
  selfCheckoutPaymentMethod: "ticket",
  selfCheckoutSubscriptionType: "FLEX",
  interestedInService: false,
  maxValueAllowedForCardPayment: 0,

  /* mock for now */
  mockimpactedInTrial: [],
};

const getters = {
  maxValueAllowedForCardPayment: (state) => state.maxValueAllowedForCardPayment,
  currentSelectedPlan: (state) =>
    state.plans.find((plan) => plan.sku === state.selectedPlanSKU) || {},
  selectedPlanSKU: (state) => state.selectedPlanSKU,
  plans: (state) => state.plans,
  hasPlans: (state) => state.plans.length > 0,
  selectedPlanProducts: (state) => {
    if (state.selectedPlanSKU) {
      const plan = state.plans.find(
        (plan) => plan.sku === state.selectedPlanSKU
      );

      return plan ? plan.products.map((item) => item.name) : [];
    }
    return [];
  },
  selectedPlanName: (state) => {
    const plan = state.selectedPlanSKU
      ? state.plans.find((plan) => plan.sku === state.selectedPlanSKU)
      : false;
    return plan && state.selectedPlanSKU ? plan.name : "";
  },
  loadingPlans: (state) => state.loadingPlans,

  clientHasThisPlan: (state, getters) => {
    const { currentCompanyPlan, selectedPlanSKU } = getters;

    if (selectedPlanSKU && currentCompanyPlan) {
      return currentCompanyPlan.status == STATUS.ACTIVE;
    }

    return false;
  },

  /* SELF CHECKOUT STORE GETTERS */

  selfCheckoutPlan: (state) => state.selfCheckoutPlan,

  isIntrestedInService: (state) => state.interestedInService,

  selfCheckoutPlanName: (state) =>
    state.selfCheckoutPlan ? state.selfCheckoutPlan.name : "",

  selfCheckoutPlanSlug: (state, getters) =>
    getters.selfCheckoutPlanName.toLowerCase().replace(" ", "_"),

  selfCheckoutPlanKey: (state) =>
    state.selfCheckoutPlan ? state.selfCheckoutPlan.key : "",

  selfCheckoutPlanProducts: (state) =>
    state.selfCheckoutPlan && state.selfCheckoutPlan.products
      ? state.selfCheckoutPlan.products.map((product) => product.name)
      : [],

  selfCheckoutPaymentMethod: (state) => state.selfCheckoutPaymentMethod,

  isCreditCardPayment: (state) =>
    state.selfCheckoutPaymentMethod === "credit_card",

  companyHasThisPlanActive: (state, getters, rootState, rootGetters) => {
    const statusSet = [STATUS.ACTIVE, STATUS.TRIAL];

    return rootGetters.contractedPlans.filter(
      (plan) =>
        statusSet.includes(plan.valid_status) &&
        plan.plan_key === getters.selfCheckoutPlanKey
    );
  },

  // companyImpactedPlansInTrial: (state, getters, rootState, rootGetters) => {
  //   const statusSet = [STATUS.ACTIVE, STATUS.TRIAL];
  //   const productNames = getters.selfCheckoutPlanProducts; // Produtos do plano atual

  //   let clientPlans = rootGetters.contractedPlans.filter(
  //     (plan) =>
  //       statusSet.includes(plan.status) &&
  //       plan.plan_key !== getters.selfCheckoutPlanKey
  //   );

  //   if (getters.selfCheckoutPlanKey && clientPlans) {
  //     return clientPlans.filter((companyPlan) => {
  //       let clientProducts = companyPlan.plan.products;

  //       return clientProducts.find((product) => {
  //         if (productNames.includes(product.name)) {
  //           return companyPlan;
  //         }
  //       });
  //     });
  //   }
  //   return [];
  // },

  companyImpactedPlansInTrial: (state) => state.mockimpactedInTrial,

  // companyImpactedPlans: (state, getters, rootState, rootGetters) => {
  //   const statusSet = [STATUS.ACTIVE];
  //   const productNames = getters.selfCheckoutPlanProducts; // Produtos do plano atual

  //   let clientPlans = rootGetters.contractedPlans.filter(
  //     (plan) =>
  //       statusSet.includes(plan.status) &&
  //       plan.plan_key !== getters.selfCheckoutPlanKey
  //   );

  //   if (getters.selfCheckoutPlanKey && clientPlans) {
  //     return clientPlans.filter((companyPlan) => {
  //       let clientProducts = companyPlan.plan.products;

  //       return clientProducts.find((product) => {
  //         if (productNames.includes(product.name)) {
  //           return companyPlan;
  //         }
  //       });
  //     });
  //   }
  //   return [];
  // },

  companyImpactedPlans: (state) => state.mockimpactedInTrial,

  selfCheckoutMaxLicenses: (state) => state.maxLicenses,

  selfCheckoutSubscriptionType: (state) => state.selfCheckoutSubscriptionType,

  userHasSelectedPlan: (state, getters, rootState, rootGetters) => {
    const key = getters.selfCheckoutPlanKey;
    const subscriptionType = getters.selfCheckoutSubscriptionType;
    if (rootGetters.contractedPlans) {
      return rootGetters.contractedPlans.find(
        (plan) =>
          plan.key === key &&
          plan.subscription_type === subscriptionType &&
          [STATUS.ACTIVE].includes(plan.valid_status)
      );
    }
    return false;
  },

  companyDiscountByPartner: (state, getters, rootState, rootGetters) => {
    if (rootGetters.company.reseller_company) {
      return parseFloat(rootGetters.company.reseller_company.discount);
    }
    return 0;
  },

  selfCheckoutDiscount: (state, getters) => {
    const discountBySubscription = getters.selfCheckoutSubscriptionType
      ? defaultDiscounts[getters.selfCheckoutSubscriptionType]
      : 0;

    if (getters.userHasSelectedPlan) {
      return getters.userHasSelectedPlan.discount;
    } else if (getters.companyDiscountByPartner > 0) {
      return (
        1 -
        (1 - discountBySubscription) * (1 - getters.companyDiscountByPartner)
      );
    }
    return discountBySubscription;
  },

  selfCheckoutDiscountBySubscripton: (state, getters) => {
    return {
      ANNUAL: getters.annualWithPartnerDiscount / 100,
      FLEX: getters.flexWithPartnerDiscount / 100,
    };
  },

  annualDefaultDiscountText: () => (defaultDiscounts.ANNUAL * 100).toFixed(2),

  annualWithPartnerDiscount: (state, getters) => {
    const discount =
      1 -
      (1 - defaultDiscounts.ANNUAL) * (1 - getters.companyDiscountByPartner);

    return (discount * 100).toFixed(2);
  },

  flexWithPartnerDiscount: (state, getters) => {
    const discount =
      1 - (1 - defaultDiscounts.FLEX) * (1 - getters.companyDiscountByPartner);

    return (discount * 100).toFixed(2);
  },

  selfCheckoutDiscountText: (state, getters) => {
    if (getters.selfCheckoutDiscount > 0) {
      let discount = parseFloat(getters.selfCheckoutDiscount) * 100 || 0;

      if (hasDecimal(discount)) {
        return `${discount.toFixed(2)}%`;
      }
      return `${parseInt(discount)}%`;
    }
    return "";
  },

  billiableLicenses: (state, getters, rootState, rootGetters) => {
    if (getters.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL) {
      return getters.selfCheckoutMaxLicenses;
    }
    return rootGetters.usersNumber < MIN_LICENSES
      ? MIN_LICENSES
      : rootGetters.usersNumber;
  },

  selfChekoutTotalPrice: (state, getters) => {
    if (state.selfCheckoutPlan) {
      const { price } = getters.selfCheckoutPlan;
      return getTotalPlanPrice(
        getters.billiableLicenses,
        getters.selfCheckoutSubscriptionType,
        getters.selfCheckoutDiscount,
        price
      );
    }
    return 0;
  },

  selfCheckoutTotalPriceWithoutDiscount: (state, getters) => {
    if (state.selfCheckoutPlan) {
      const { price } = getters.selfCheckoutPlan;
      return getTotalPlanPriceWithoutDiscount(
        getters.billiableLicenses, // q isso?
        getters.selfCheckoutSubscriptionType,
        price
      );
    }
    return 0;
  },

  selfCheckoutDiscountInMoney: (state, getters) =>
    getters.selfCheckoutTotalPriceWithoutDiscount -
    getters.selfChekoutTotalPrice,

  selfCheckoutPriceByPeriod: (state, getters) => {
    if (state.selfCheckoutPlan) {
      const { price } = getters.selfCheckoutPlan;
      return getPriceByPeriod(
        getters.selfCheckoutSubscriptionType,
        price,
        getters.selfCheckoutDiscount
      );
    }
    return 0;
  },

  temporaryCompanyPlan: (state, getters, rootState, rootGetters) => {
    if (state.selfCheckoutPlan) {
      let companyPlan = {
        plan: state.selfCheckoutPlan,
        licenses: rootGetters.usersNumber,
        price: state.selfCheckoutPlan.price,
        subscription_type: getters.selfCheckoutSubscriptionType,
        discount:
          getters.selfCheckoutDiscountBySubscripton[
            getters.selfCheckoutSubscriptionType
          ],
      };

      if (getters.selfCheckoutSubscriptionType === SUBSCRIPTIONS.ANNUAL) {
        companyPlan.max_licenses = getters.selfCheckoutMaxLicenses;
      }

      return companyPlan;
    }
    return {};
  },

  /* REVER ISSO AQUI */
  impactedCompanyPlans: (state, getters) => {
    if (!getters.currentCompanyPlan || !getters.selfCheckoutPlan) return false;

    const statusSet = ["ACTIVE", "TRIAL"];
    if (!statusSet.includes(getters.currentCompanyPlan.status)) return false;

    const { plan } = getters.currentCompanyPlan;
    const productNames = getters.selfCheckoutPlan.products;

    return plan.products.find((product) => {
      if (productNames.includes(product.name))
        return getters.currentCompanyPlan;
    });
  },

  hasEndedTrial: (_, getters) => {
    if (!getters.currentCompanyPlan) return true;

    if (getters.currentCompanyPlan.status === "TRIAL") {
      return checkExpiredDate(getters.currentCompanyPlan.end_trial);
    }

    return false;
  },
};

// actions
const actions = {
  async getPlans({ getters, commit }) {
    if (!getters.plans || getters.plans.length == 0) {
      commit("setLoadingPlans", true);
      let url = "/integrations/conecta-control/plans";
      await suiteAPIClient.get(url).then(({ data }) => {
        commit("setPlans", data);
      });
      commit("setLoadingPlans", false);
    }
  },

  hireCompanyPlan(_, payload) {
    let url = "/integrations/conecta-control/company-plan";
    return suiteAPIClient.post(url, payload);
  },

  selfCheckoutSendSuperLogicaEmailPayment({ getters }) {
    let url = "/integrations/control/superlogica/email-payment";
    const payload = { suite_company_key: getters.companyInSuite.key };
    return suiteAPIClient.post(url, payload);
  },
};

// mutations
const mutations = {
  setMaxValueAllowedForCardPayment(state, value = 0) {
    state.maxValueAllowedForCardPayment = value;
  },

  setSelectedPlanKSKU(state, key) {
    state.selectedPlanSKU = key;
  },
  setPlans(state, plans) {
    state.plans = plans;
  },

  setSelfCheckoutPaymentMethod(state, payment_method) {
    state.selfCheckoutPaymentMethod = payment_method;
  },

  setSelfCheckoutPlanInCart(state, plan) {
    state.selfCheckoutPlan = plan;
  },

  setSelfCheckoutMaxLicenses(state, licenses) {
    state.maxLicenses = licenses < MIN_LICENSES ? MIN_LICENSES : licenses;
  },

  setSelfCheckoutSubscriptionType(state, subscription_type) {
    state.selfCheckoutSubscriptionType = subscription_type;
  },

  setIntrestInService(state, value) {
    state.interestedInService = value;
  },

  setLoadingPlans(state, value) {
    state.loadingPlans = value;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
