<template>
  <v-app>
    <v-container>
      <v-overlay
        :value="true"
        opacity="1"
        z-index="200"
        color="primary darken-5"
        style="text-align: center"
      >
        <v-progress-circular width="10" size="110" color="white" indeterminate>
          <Logo
            product="conecta_control"
            height="55"
            noMargins
            white
            class="blink"
          />
        </v-progress-circular>
        <transition
          v-for="(message, index) in messages"
          :key="`loading-${index}`"
          name="fade-transition"
          mode="out-in"
        >
          <div
            :key="message"
            v-html="message || $ml.get('loading')"
            class="text-h6 font-weight-regular pt-6"
          />
        </transition>
      </v-overlay>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "PageLoading",

  props: {
    messages: { type: Array, default: () => [""] },
  },
};
</script>

<style lang="scss" scoped>
.icon-spinner {
  animation: spin-animation 1.5s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
