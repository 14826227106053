const state = () => ({
  token: localStorage.getItem("token"),
  authError: false,
  isAuthenticating: false,
});

const getters = {
  token: (state) => state.token,
  authError: (state) => state.authError,
  isAuthenticating: (state) => state.isAuthenticating,
  hasFilledForm: (state, { companyInSuite }) =>
    companyInSuite && companyInSuite.verified_phone,
  isSimulation: (state, { currentUser }) =>
    currentUser && currentUser.is_simulated,
};

const actions = {
  generateToken({ commit }, auth) {
    const url = auth.retry ? "/auth/authorizate" : "/auth/callback";

    if (auth.retry) delete auth.retry;

    return this._vm.$axios
      .get(url, auth)
      .then(({ data }) => {
        commit("setToken", data.token);
        return Promise.resolve(data.token);
      })
      .catch(({ response }) => {
        return Promise.reject(response.data);
      });
  },

  exchangeToken({ commit, dispatch }, suiteKey = null) {
    if (suiteKey) {
      return this._vm.$axios
        .get(`auth/suite-customers/${suiteKey}/token`)
        .then(({ data }) => {
          commit("setToken", data.token);
          return Promise.resolve(data.token);
        })
        .catch(({ response }) => {
          return Promise.reject(response.data);
        });
    }

    return this._vm.$axios
      .get("auth/exchange-token")
      .then(({ data }) => {
        commit("setToken", data.token);
        dispatch("getCurrentUser");
        return Promise.resolve(data.token);
      })
      .catch(({ response }) => {
        return Promise.reject(response.data.code);
      });
  },

  logout({ commit }) {
    commit("setToken", null);
    location.reload();
  },
};

const mutations = {
  setToken(state, token) {
    state.token = token;
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
    this._vm.$axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${token}`;
  },
  setAuthError(state, error) {
    state.authError = error;
  },
  setIsAuthenticating(state, isAuthenticating) {
    state.isAuthenticating = isAuthenticating;
  },
  setShowAccessForm(state, show) {
    state.showAccesForm = show;
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
