<template>
  <div>
    <SubscriptionTypeTabs />

    <v-row v-if="usersNumber < MIN_LICENSES" no-gutters>
      <Alert
        :prominent="false"
        class="mt-2 mx-5"
        type="warning"
        text-color="warning--text text--darken-4"
        dense
        :width="700"
        :show-action="false"
      >
        {{ $ml.get("minLicensesInfo") }}
      </Alert>
    </v-row>

    <v-data-iterator :items="availablePlans" hide-default-footer>
      <template v-slot:header>
        <v-row
          v-if="$vuetify.breakpoint.mdAndDown"
          justify="center"
          class="ma-0 pa-0 mt-5 mb-8"
        >
          <LicensesDialogSelector
            :subscription-type="subscriptionType"
            @update="updatedMaxLicenses = $event"
          />
        </v-row>
      </template>
      <template>
        <div v-if="$vuetify.breakpoint.lgAndUp">
          <PlanFeaturesExpasion
            show-plans
            :licenses="updatedMaxLicenses"
            :subscription-type="subscriptionType"
            @select-plan="$emit('select-plan', $event)"
          />
        </div>

        <div v-else>
          <!-- VERSÃO TABLET OU MOBILE -->
          <v-row class="ma-0 pa-0">
            <v-col v-if="!isCellPhoneScreen">
              <UpgradePlanCard
                :subscription-type="subscriptionType"
                :licences="updatedMaxLicenses"
                :plan="featuredPlan"
                @select-plan="$emit('select-plan', $event)"
              />

              <v-divider></v-divider>

              <PlanDescription maxWidth="400" :plan="featuredPlan" />
            </v-col>
            <v-col>
              <!-- VERSÃO MOBILE -->
              <v-carousel
                v-model="caru"
                hide-delimiters
                interval="5000"
                height="300"
                class="pa-0"
              >
                <v-carousel-item
                  v-for="(activePlan, index) in mobilePlansList"
                  :key="!isCellPhoneScreen ? index + 1 : index"
                  class="pa-0"
                >
                  <UpgradePlanCard
                    :class="`${
                      $vuetify.breakpoint.lgAndUp &&
                      index < props.items.lenght - 1
                        ? 'border-right'
                        : ''
                    }`"
                    :licences="updatedMaxLicenses"
                    :subscription-type="subscriptionType"
                    :plan="activePlan"
                    @select-plan="$emit('select-plan', $event)"
                  />
                </v-carousel-item>
              </v-carousel>

              <v-divider></v-divider>

              <PlanDescription maxWidth="400" :plan="mobilePlansList[caru]" />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import {
  STATUS,
  FEATURED_SKU,
  MIN_LICENSES,
} from "@/helpers/backendConstants.js";

import UpgradePlanCard from "@/components/purchase/selfcheckout/UpgradePlanCard";
import PlanFeaturesExpasion from "@/components/purchase/selfcheckout/PlanFeaturesExpasion.vue";
import LicensesDialogSelector from "@/components/purchase/LicensesDialogSelector.vue";
import SubscriptionTypeTabs from "@/components/purchase/selfcheckout/SubscriptionTypeTabs.vue";
import PlanDescription from "@/components/purchase/selfcheckout/PlanDescription";

export default {
  name: "PurchasePlansSelector",

  components: {
    UpgradePlanCard,
    PlanFeaturesExpasion,
    LicensesDialogSelector,
    SubscriptionTypeTabs,
    PlanDescription,
  },

  data() {
    return {
      STATUS,
      FEATURED_SKU,
      MIN_LICENSES,
      caru: 0,
    };
  },

  computed: {
    ...mapGetters([
      "plans",
      "usersNumber",
      "currentCompanyPlan",
      "selfCheckoutMaxLicenses",
      "selfCheckoutSubscriptionType",
    ]),

    updatedMaxLicenses: {
      get() {
        return this.selfCheckoutMaxLicenses;
      },
      set(newVal) {
        if (newVal >= this.usersNumber) {
          this.setSelfCheckoutMaxLicenses(newVal);
        }
      },
    },

    subscriptionType() {
      return this.selfCheckoutSubscriptionType;
    },

    featuredPlan() {
      return this.availablePlans.find((plan) => plan.sku === FEATURED_SKU);
    },

    nonFeaturedPlans() {
      return this.availablePlans.filter((plan) => plan.sku !== FEATURED_SKU);
    },

    isCellPhoneScreen() {
      return this.$vuetify.breakpoint.xs;
    },

    availablePlans() {
      return this.plans
        .filter((plan) => plan.is_active && plan.is_visible)
        .sort(function (a, b) {
          if (a.short_code === "conecta_suite") {
            return -1;
          }
          if (b.short_code === "conecta_suite") {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          if (a.price > b.price) {
            return 1;
          }
          return 0;
        });
    },

    mobilePlansList() {
      if (this.isCellPhoneScreen) {
        return this.availablePlans;
      }
      return this.nonFeaturedPlans;
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutMaxLicenses"]),

    checkIfClientHasThisPlan(plan_key) {
      if (!this.currentCompanyPlan) return false;
      const { key, valid_status, subscription_type } = this.currentCompanyPlan;

      return (
        key === plan_key &&
        STATUS.ACTIVE == valid_status &&
        this.selfCheckoutSubscriptionType == subscription_type
      );
    },
  },
};
</script>
